<template>
    <v-row justify="center">
        <v-dialog
        persistent
        max-width="600px"
        v-model="dialog"
        >
            <v-card dark>
                <v-card-title>
                    <span class="text-h6">เพิ่มข้อมูล</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="isValid">
                    <v-container>
                        <v-row class="px-3">
                            <v-text-field
                                color="yellow darken-3"
                                label="น้ำหนัก"
                                v-model="form.weight"
                                :rules="rules.number"
                            >
                            </v-text-field>
                            <div style="width: 100px">
                                <v-select
                                dark
                                :items="[{ name:'กรัม', value: 'g'}, { name: 'บาท', value: 'b'}]"
                                item-text="name"
                                item-value="value"
                                color="yellow darken-3"
                                v-model="form.unit"
                                :light="false"
                                >
                                </v-select>
                            </div>
                        </v-row>
                        <v-row class="mt-n4">
                            <v-col>
                                <v-text-field
                                    color="yellow darken-3"
                                    label="ระยะทาง 1-5"
                                    v-model="form.range1"
                                    hint="เฉพาะตัวเลขเช่น 0.25, 10 เป็นต้น"
                                    :rules="rules.number"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    color="yellow darken-3"
                                    label="ระยะทาง 6-10"
                                    v-model="form.range2"
                                    hint="เฉพาะตัวเลขเช่น 0.25, 10 เป็นต้น"
                                    :rules="rules.number"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n5">
                            <v-col>
                                <v-text-field
                                    color="yellow darken-3"
                                    label="ระยะทาง 11-20"
                                    v-model="form.range3"
                                    hint="เฉพาะตัวเลขเช่น 0.25, 10 เป็นต้น"
                                    :rules="rules.number"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    color="yellow darken-3"
                                    label="ระยะทาง 21-30"
                                    v-model="form.range4"
                                    hint="เฉพาะตัวเลขเช่น 0.25, 10 เป็นต้น"
                                    :rules="rules.number"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n5">
                            <v-col>
                                <v-text-field
                                    color="yellow darken-3"
                                    label="ระยะทาง 31-40"
                                    v-model="form.range5"
                                    hint="เฉพาะตัวเลขเช่น 0.25, 10 เป็นต้น"
                                    :rules="rules.number"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-alert v-show="errorMessage != ''" text type="error"> {{ errorMessage }} </v-alert>
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions class="mt-n5">
                <v-spacer></v-spacer>
                <v-btn
                    color="grey darken-1"
                    text
                    @click="hide"
                >
                    Close
                </v-btn>
                <v-btn
                    color="yellow darken-3"
                    text
                    @click="submit"
                >
                    Save
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import axios from 'axios';
import { getPricingById } from '../models/setting';

export default {
    data: () => ({
        dialog: false,
        form: {
            unit: 'g'
        },
        rules: {
            number: [
                v => {
                    const pattern = /^\d+(\.\d+)?$/;
                    return pattern.test(v) || 'เฉพาะตัวเลขเท่านั้น';
                }
            ]
        },
        isValid: false,
        errorMessage: '',
        id: null
    }),
    methods: {
        show(id){
            if(id || 0 === id){
                this.id = id;
                this.getData();
            }else{
                this.id = null;
            }
            this.errorMessage = '';
            this.dialog = true;
        },
        hide(){
            this.form = {
                unit: 'g'
            };
            this.dialog = false;
            this.$refs.form.resetValidation();
        },
        async getData(){
            let loader = this.$loading.show();
            try{
                this.form = await getPricingById(this.id);
            }catch(error){
                this.errorMessage = error.response?.data[0]?.msg || error.response?.data?.fail || error.message;
            }finally{
                loader.hide();
            }
        },
        async submit(){
            this.$refs.form.validate();
            this.errorMessage = '';
            if(this.isValid){
                let loader = this.$loading.show();
                try{
                    if(null == this.id){ // create
                        await axios.post(process.env.VUE_APP_BASE_URL + '/settings', this.form);
                    }else{ //edit
                        await axios.patch(process.env.VUE_APP_BASE_URL + '/settings/' + this.id, this.form);
                    }
                    
                    this.$emit('finish');
                    this.hide();
                }catch(error){
                    this.errorMessage = error.response?.data[0]?.msg || error.response?.data?.fail || error.message;
                }finally{
                    loader.hide();
                }
            }
        }
    }
};
</script>