/* eslint-disable no-unused-vars */
import axios from 'axios';
import fd from 'js-file-download';
import Text from '../utils/text-mapping';

class Job {
    constructor(data) {
        this.id = data.id;
        this.ownerType = data.ownerType;
    }
}

class JobProduct {
    constructor(data) {
        this.id = data.id;
        this.type = data.type;
        this.subType = data.subType;
        this.details = data.details;
        this.weight = {
            value: data.weight.value,
            unit: data.weight.unit
        };
        this.quantity = {
            value: data.quantity.value,
            unit: data.quantity.unit
        };
        this.price = data.price;
        this.note = data.note;

    }
}

const mockProduct = () => {
    return new JobProduct({ id: new Date().getTime(), type: '1', subType: '1', details: '', weight: { value: null, unit: 'b' }, quantity: { value: null, unit: 'ea' }, price: null, note: '' });
};

const getChannelName = (value) => {
    return Text.channelText(value);
};

/**
 * Change to thai name
 * @param {String} value 
 */
const getStatusThaiName = (value) => {
    if ('before_new' == value) {
        return 'มาใหม่';
    } else if ('new' == value) {
        return 'ส่งคำขออนุมัติ';
    } else if ('wait_confirm' == value || 'wait_confirm_with_approval' == value) {
        return 'รออนุมัติ';
    } else if ('wait_messenger' == value) {
        return 'ที่ต้องจัดส่ง';
    } else if ('sending' == value) {
        return 'กำลังส่ง';
    } else if ('sent' == value) {
        return 'จัดส่งสำเร็จ';
    } else if ('cancel' == value) {
        return 'ยกเลิก';
    }
};

const goldBar = [ // ทองคำแท่ง
    { name: '96.50%', value: '1' },
    { name: '99.99%', value: '2' }
];

const goldJew = [ // ทองรูปพรรณ
    { name: 'เก่า 96.50%', value: '1' },
    { name: 'เก่า 99.99%', value: '2' },
    { name: 'ใหม่ 96.50%', value: '3' },
    { name: 'ใหม่ 99.99%', value: '4' }
];

const jewely = [ // อัญมณีและเครื่องประดับ
    { name: 'โลหะมีค่า', value: '1' },
    { name: 'เพชร', value: '2' },
    { name: 'พลอย', value: '3' },
    { name: 'เครื่องประดับ', value: '4' }
];

/**
 * 
 */
const listProductType = [
    { name: 'ทองคำแท่ง', value: '2' },
    { name: 'ทองรูปพรรณ', value: '3' },
    { name: 'อัญมณีและเครื่องประดับ', value: '4' },
    { name: 'เงินสด', value: '1' },
    { name: 'เอกสาร / พัสดุ', value: '5' },
    { name: 'อื่นๆ', value: '6' }
];

// base on listProductType
const weightUnit = {
    1: [
        { name: 'บาท', value: 'b' }
    ],
    2: [
        { name: 'บาท', value: 'b' },
        { name: 'กรัม', value: 'g' },
        { name: 'กิโลกรัม', value: 'kg' }
    ],
    3: [
        { name: 'บาท', value: 'b' },
        { name: 'กรัม', value: 'g' },
        { name: 'กิโลกรัม', value: 'kg' }
    ],
    4: [],
    5: [
        { name: 'กรัม', value: 'g' }
    ],
    6: [
        { name: 'กรัม', value: 'g' }
    ]
};

/**
 * base on listProductType
 * 1 = ชิ้น
 * 2 = เส้น
 * 3 = วง
 * 4 = คู่
 */
const quantityUnit = {
    1: [],
    2: [
        { name: 'ชิ้น', value: '1' }
    ],
    3: [
        { name: 'ชิ้น', value: '1' },
        { name: 'เส้น', value: '2' },
        { name: 'วง', value: '3' },
        { name: 'คู่', value: '4' }
    ],
    4: [
        { name: 'ชิ้น', value: '1' },
        { name: 'เส้น', value: '2' },
        { name: 'วง', value: '3' },
        { name: 'คู่', value: '4' }
    ],
    5: [
        { name: 'ชิ้น', value: '1' }
    ],
    6: [
        { name: 'ชิ้น', value: '1' }
    ]
};

/**
 * Product Type
 * 1 = Move sameday
 * 2 = Move transit
 * 3 = Move express
 * 
 * Sub Product Type
 * 1 = เงินสด
 * 2 = ทองคำแท่ง
 * 3 = ทองรูปพรรณ
 * 4 = อัญมณีและเครื่องประดับ
 * 5 = เอกสารพัสดุ
 * 6 = อื่นๆ
 */
const channelOptions = {
    1: {
        productType: [
            listProductType[0],
            listProductType[1],
            listProductType[2],
            listProductType[3],
            listProductType[4],
            listProductType[5]
        ],
        subProductType: {
            1: [],
            2: goldBar,
            3: goldJew,
            4: jewely,
            5: [],
            6: []
        },
        weightUnit: weightUnit,
        quantityUnit: quantityUnit
    },
    2: {
        productType: [
            listProductType[0],
            listProductType[1],
            listProductType[2],
            listProductType[3],
            listProductType[4],
            listProductType[5]
        ],
        subProductType: {
            1: [],
            2: goldBar,
            3: goldJew,
            4: jewely,
            5: [],
            6: []
        },
        weightUnit: weightUnit,
        quantityUnit: quantityUnit
    },
    3: {
        productType: [
            listProductType[1],
            listProductType[2],
            listProductType[3],
            listProductType[4],
            listProductType[5]
        ],
        subProductType: {
            1: [],
            2: goldBar,
            3: goldJew,
            4: jewely,
            5: [],
            6: []
        },
        weightUnit: weightUnit,
        quantityUnit: quantityUnit
    }
};

const responseDraftToForm = (data) => {
    const form = {
        code: data.code,
        channel: data.channel,
        customer: data.customer,
        orderType: data.ordering.type,
        products: data.products.map(p => {
            return {
                id: new Date().getTime() + Math.random(),
                type: p.type,
                subType: p.subType,
                details: p.details,
                weight: p.weight,
                quantity: p.quantity ? p.quantity : { value: '', unit: '1' },
                price: p.price,
                note: p.note
            };
        }),
        ordering: {
            weight: data.ordering.sumWeight,
            address1Start: {
                id: 'address1Start',
                address1: data.ordering.address1.start.address1,
                address2: data.ordering.address1.start.address2,
                location: data.ordering.address1.start.location,
                text: `${data.ordering.address1.start.address2} ${data.ordering.address1.start.address1}`
            },
            address1Destination: {
                id: 'address1Destination',
                address1: data.ordering.address1.destination.address1,
                address2: data.ordering.address1.destination.address2,
                location: data.ordering.address1.destination.location,
                text: `${data.ordering.address1.destination.address2} ${data.ordering.address1.destination.address1}`
            },
            address2Start: {
                id: 'address2Start',
                address1: data.ordering.address2?.start.address1 || '',
                address2: data.ordering.address2?.start.address2 || '',
                location: data.ordering.address2?.start.location || null,
                text: `${data.ordering.address2?.start.address2 || ''} ${data.ordering.address2?.start.address1 || ''}`
            },
            address2Destination: {
                id: 'address2Destination',
                address1: data.ordering.address2?.destination.address1 || '',
                address2: data.ordering.address2?.destination.address2 || '',
                location: data.ordering.address2?.destination.location || null,
                text: `${data.ordering.address2?.destination.address2 || ''} ${data.ordering.address2?.destination.address1 || ''}`
            },
            length: data.ordering.length,
            width: data.ordering.width,
            height: data.ordering.height,
            shippingFee: data.ordering.shippingFee
        },
        paymentType: data.ordering.paymentType,
        paymentNote: data.ordering.paymentNote,
        paymentFileDownload: data.ordering.paymentFile ? {
            name: data.ordering.paymentFile.name,
            url: data.ordering.paymentFile.url
        } : null,
        isDeletePaymentFile: false,
        createdAt: data.createdAt
    };
    return form;
};

const getDraftApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_BASE_URL + '/jobs/draft')
            .then(response => {
                // transform response to data form
                const data = response.data.job;
                if (!data) {
                    return resolve(null);
                }
                resolve(responseDraftToForm(data));
            })
            .catch(error => reject(error));
    });
};

const jsonToFormData = (json) => {
    const formData = new FormData();
    formData.append('code', json.code || 'CODE3EASD');
    formData.append('customerType', json.customer.type);
    formData.append('customerCompany', json.customer.company);
    formData.append('customerName', json.customer.name);
    formData.append('customerMobile', json.customer.mobile);
    formData.append('customerEmail', json.customer.email);
    formData.append('customerAddress', json.customer.address);
    formData.append('customerIdTax', json.customer.idTax);

    formData.append('channel', json.channel);
    formData.append('orderingSumWeightValue', json.ordering.weight.value);
    formData.append('orderingSumWeightUnit', json.ordering.weight.unit);
    formData.append('orderingWidth', json.ordering.width || 0);
    formData.append('orderingLength', json.ordering.length || 0);
    formData.append('orderingHeight', json.ordering.height || 0);
    formData.append('orderingType', json.orderType);
    formData.append('orderingShippingFee', json.ordering.shippingFee);

    formData.append('orderingAddress1Start_address1', json.ordering.address1Start.address1);
    formData.append('orderingAddress1Start_address2', json.ordering.address1Start.address2);
    formData.append('orderingAddress1Start_lat', json.ordering.address1Start.location?.lat || null);
    formData.append('orderingAddress1Start_lng', json.ordering.address1Start.location?.lng || null);

    formData.append('orderingAddress1Destination_address1', json.ordering.address1Destination.address1);
    formData.append('orderingAddress1Destination_address2', json.ordering.address1Destination.address2);
    formData.append('orderingAddress1Destination_lat', json.ordering.address1Destination.location?.lat || null);
    formData.append('orderingAddress1Destination_lng', json.ordering.address1Destination.location?.lng || null);

    formData.append('orderingAddress2Start_address1', json.ordering.address2Start.address1);
    formData.append('orderingAddress2Start_address2', json.ordering.address2Start.address2);
    formData.append('orderingAddress2Start_lat', json.ordering.address2Start.location?.lat || null);
    formData.append('orderingAddress2Start_lng', json.ordering.address2Start.location?.lng || null);

    formData.append('orderingAddress2Destination_address1', json.ordering.address2Destination.address1);
    formData.append('orderingAddress2Destination_address2', json.ordering.address2Destination.address2);
    formData.append('orderingAddress2Destination_lat', json.ordering.address2Destination.location?.lat || null);
    formData.append('orderingAddress2Destination_lng', json.ordering.address2Destination.location?.lng || null);

    formData.append('paymentType', json.paymentType);
    formData.append('paymentNote', json.paymentNote);
    formData.append('isDeletePaymentFile', json.isDeletePaymentFile);

    // product
    json.products.forEach(p => {
        formData.append('products', JSON.stringify(p));
    });

    // payment file
    if (json.paymentFile) {
        formData.append('paymentFile', json.paymentFile);
    }

    // create stage
    if (json.paymentFile) {
        formData.append('paymentFileAt', 'new');
    } else if (json.paymentFileDownload != null) {
        formData.append('paymentFileAt', 'draft');
    } else {
        formData.append('paymentFileAt', 'none');
    }

    return formData;
};

/**
 * form is json form (JobForm.vue)
 * @param {JSON} form 
 * @returns 
 */
const saveDraftApi = (form) => {
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_BASE_URL + '/jobs/draft', jsonToFormData(form), { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => reject(error));
    });
};

const createJobApi = (form) => {
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_BASE_URL + '/jobs', jsonToFormData(form), { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => reject(error));
    });
};

/**
 * 
 * @param {JSON} download object to download file
 */
const downloadPaymentFile = (download) => {
    axios.post(download.url, {}, { responseType: 'blob' })
        .then(response => {
            fd(response.data, download.name, response.headers['content-type']);
        });
};

/**
 * 
 */
const getJobsList = (isMinimal, textSearch, startDate, endDate, status = '') => {
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_BASE_URL
            + `/jobs?isMinimal=${isMinimal}&textSearch=${textSearch}&startDate=${startDate}&endDate=${endDate}&status=${status}`)
            .then(response => {
                resolve(response);
            })
            .catch(error => reject(error));
    });
};

const getJobById = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_BASE_URL + `/jobs/${id}`)
            .then(response => {
                const data = response.data.job;
                if (!data) {
                    return resolve(null);
                }
                resolve(data);
            })
            .catch(error => reject(error));
    });
};

const downloadCover = (job) => {
    axios.post(process.env.VUE_APP_BASE_URL + '/reports/order', { jobs: [job.id] }, { responseType: 'blob' })
        .then(response => {
            fd(response.data, job.code + '.pdf', response.headers['content-type']);
        });
};

const downloadCSV = (ids) => {
    axios.post(process.env.VUE_APP_BASE_URL + '/reports/job', { jobs: ids.map(j => j.id) }, { responseType: 'blob' })
        .then(response => {
            fd(response.data, 'job.csv', response.headers['content-type']);
        });
};

const updateShippingCustomer = (job, price) => {
    return axios.post(process.env.VUE_APP_BASE_URL + '/jobs/update-customer-shipping/' + job.id, { price: price });
};

const colorStatus = (value) => {
    if ('new' == value) {
        return 'cyan darken-3';
    } else if ('wait_confirm' == value || 'wait_confirm_with_approval' == value) {
        return 'yellow darken-3';
    } else if ('wait_messenger' == value) {
        return 'blue darken-3';
    } else if ('sending' == value) {
        return 'red darken-3';
    } else if ('sent' == value) {
        return 'green darken-3';
    } else if ('cancel' == value) {
        return 'grey darken-3';
    } else if ('before_new' == value) {
        return 'pink darken-3';
    }
};

export {
    Job,
    JobProduct,
    mockProduct,
    channelOptions,
    getDraftApi,
    saveDraftApi,
    downloadPaymentFile,
    getChannelName,
    createJobApi,
    getJobsList,
    getJobById,
    getStatusThaiName,
    downloadCover,
    downloadCSV,
    updateShippingCustomer,
    colorStatus
};