var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mt-4"},[_c('p',{staticClass:"text-h5 white--text"},[_vm._v("ประวัติการใช้งานระบบ")])])],1),_c('v-row',[_c('div',{staticStyle:{"width":"290px"}},[_c('v-col',[_c('v-menu',{attrs:{"dark":"","close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dark":"","label":"วันที่ค้นหา","hint":"เลือกวันที่ค้นหา","persistent-hint":"","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateText),callback:function ($$v) {_vm.dateText=$$v},expression:"dateText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"color":"grey darken-1","no-title":""},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)],1)]),_c('v-row',[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%","border":"0.5px solid #aaaaaa"},attrs:{"loading":_vm.isLoading,"loading-text":"กำลังโหลด....... กรุณารอซักครู่","headers":_vm.headers,"items-per-page":100,"items":_vm.data,"no-data-text":"ไม่พบข้อมูล","dark":""},scopedSlots:_vm._u([{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatTime(item.time)))])]}},{key:"item.number",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index + 1))])]}}],null,true)})],1),_c('alert-error',{ref:"alertError",attrs:{"text":_vm.errorMessage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }