/* eslint-disable no-unused-vars */
import axios from 'axios';

const formatPricing = (pricing) => {
    if (!pricing) {
        return {};
    }
    let unitText = 'g' == pricing.unit ? 'กรัม' : 'บาท';
    const weightText = `${pricing.weight} ${unitText}`;


    return {
        id: pricing.id,
        weight: pricing.weight,
        unit: pricing.unit,
        weightText: weightText,
        range1: pricing.range1,
        range2: pricing.range2,
        range3: pricing.range3,
        range4: pricing.range4,
        range5: pricing.range5,
        createdAt: pricing.createdAt,
        createdBy: pricing.createdBy,
        updatedAt: pricing.updatedAt,
        updatedBy: pricing.updatedBy
    };

};

const compareWeight = (c1, c2) => {
    if ('b' == c1.unit && 'g' == c2.unit) {
        return 1;
    }
    if ('g' == c1.unit && 'b' == c2.unit) {
        return -1;
    }
    if (c1.unit == c2.unit) {
        if (c1.weight > c2.weight) {
            return 1;
        }
        if (c1.weight < c2.weight) {
            return -1;
        }
        if (c1.weight == c2.weight) {
            return 0;
        }
    }
};

export async function getPricing () {
    const response = await axios.get(process.env.VUE_APP_BASE_URL + '/settings');
    const data = response.data.prices;
    let prices = [];
    for (let pricing of data) {
        prices.push(formatPricing(pricing));
    }
    prices.sort(compareWeight);
    return prices;
}

export async function getPricingById (id) {
    const response = await axios.get(process.env.VUE_APP_BASE_URL + '/settings/' + id);
    const pricing = response.data.pricing;
    return formatPricing(pricing);
}
