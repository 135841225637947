<template>
    <v-container>
        <p class="text-h6 white--text font-weight-bold">จัดการคำสั่งซื้อ</p>
        <div class="d-flex justify-space-between">
            <div style="width: 300px">
                <v-text-field
                    dark
                    dense
                    label="ค้นหารายการ"
                    solo
                    prepend-inner-icon="mdi-magnify"
                    @keyup.enter="refreshData"
                    v-model="textSearch"
                >
                </v-text-field>
            </div>
            
            <div class="mt-n4">
                <div class="d-inline-flex">
                    <label class="grey--text text-body1">วันที่ทำการสั่งซื้อ</label>
                </div>
                <div class="d-inline-flex ml-4" style="width: 300px;">
                    <v-menu
                        dark
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :close-on-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        :return-value.sync="dates"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                dark
                                v-model="datesText"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="dates"
                            no-title
                            scrollable
                            range
                            color="grey darken-1"
                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="grey"
                                @click="menu = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="filterDateRange"
                            >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </div>
                
            </div>
        </div>
        <v-divider dark class="mb-5"></v-divider>
        <div class="mb-5">
            <v-chip-group
                dark
                mandatory
                active-class="primary--text"
                @change="showDataTable"
                v-model="statusFilter"
            >
            <v-chip v-for="filter of filters" :key="filter.value" :value="filter.value">
                {{ filter.name }}
            </v-chip>
            </v-chip-group>
        </div>

        <!-- Data Table -->
        <v-data-table
            dark
            v-model="selected"
            :headers="headers"
            :items-per-page="30"
            :items="dataForShow"
            :loading="isLoading"
            loading-text="กำลังโหลดข้อมูล....... กรูณารอซักครู่"
            class="elevation-1"
            style="width: 100%;border: 0.5px solid #aaaaaa;"
            show-select
            no-data-text="ไม่มีข้อมูล"
        >
            <template v-slot:top>
                <div class="d-flex pa-3">
                    <div class="mr-auto">
                        <p class="text-h6 ma-2">คำสั่งซื้อทั้งหมด  
                        <span class="yellow--text text--darken-3"> {{ String(dataForShow.length).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} รายการ </span> </p>
                    </div>
                    <div class="px-3">
                        <v-btn plain :disabled="0 == selected.length" @click="downloadMultiCover">
                            <v-icon
                                right
                                dark
                                class="mr-2"
                            >
                                mdi-cloud-print-outline
                            </v-icon>
                            พิมพ์ใบแปะหน้า
                        </v-btn>
                    </div>
                </div>
                
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
                {{ formatTimeTable(item.createdAt) }}
            </template>
            <template v-slot:[`item.channel`]="{ item }">
                {{ getChannelName(item.channel) }}
            </template>
            <template v-slot:[`item.totalPrice`]="{ item }">
                <label class="yellow--text text--darken-3"> 
                    {{ Number(item.totalPrice).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                </label>
            </template>
            <template v-slot:[`item.status`]="{item}">
                <v-chip small :color="colorStatus(item.status)">
                    <v-icon class="ml-n3" :color="colorStatus(item.status).split(' ')[0]">mdi-circle-medium</v-icon>
                    <span class="">{{ getStatusThaiName(item.status) }}</span>
                </v-chip>
            </template>
            <template v-slot:[`item.action`]="{item}">
                <v-container class="text-right">

                <v-btn
                    color="yellow darken-3"
                    depressed
                    @click="downloadCover(item)"
                >
                <span class="black--text">
                    พิมพ์ใบแปะหน้า
                </span>
                </v-btn>

                </v-container>
            </template>
        </v-data-table>

        <alert-error :text="errorMessage" ref="alertError"></alert-error>
    </v-container>
</template>

<script>
import { getJobsList, getChannelName, getStatusThaiName, downloadCover, colorStatus } from '../../models/job';
import AlertError from '../../components/AlertError.vue';
import moment from 'moment';
import Text from '../../utils/text-mapping';

export default {
    data: () => ({
        textSearch: '',
        menu: false,
        dates: [],
        datesText: '',
        isLoading: false,
        data: [],
        errorMessage: '',
        statusFilter: 0,
        filters: Text.filterChannels(),
        selected: [],
        headers: [
            { text: 'หมายเลขคำสั่งซื้อ', value: 'code', sortable: false },
            { text: 'สร้างวันที่', value: 'createdAt' },
            { text: 'มูลค่าสินค้า', value: 'totalPrice', sortable: false },
            { text: 'สถานะรายการ', value: 'status' },
            { text: 'ช่องทาง', value: 'channel' },
            { text: 'Tracking no.', value: 'track' },
            { text: '', value: 'action', sortable: false }
        ],
        dataForShow: []
    }),
    methods: {
        async refreshData(){
            this.$router.push({ 
                name: 'PrintHome', 
                query : { 
                    textSearch: this.textSearch, 
                    startDate: this.startDate || '', 
                    endDate: this.endDate || '' } 
            }).catch(() => {});
            this.isLoading = true;
            this.data = [];
            try{
                const response = await getJobsList(true, this.textSearch, this.startDate || '', this.endDate || '', 'wait_messenger');
                const jobs = response.data.jobs;
                jobs.forEach(j => {
                    this.data.push(j);
                });
                this.showDataTable();
            }catch(error) {
                console.log(error);
                this.errorMessage = error.response?.data[0]?.msg || error.response?.data || error.message;
                this.$refs.alertError.show();
            }finally{
                this.isLoading = false;
            }
        },
        filterDateRange(){
            this.$refs.menu.save(this.dates);
            this.refreshData();
        },
        showDataTable(){
            if(0 == this.statusFilter){ // all
                this.dataForShow = this.data;
            }else if(1 == this.statusFilter){ // move same day
                this.dataForShow = this.data.filter(d => '1' == d.channel);
            }else if(2 == this.statusFilter){ // move transit
                this.dataForShow = this.data.filter(d => '2' == d.channel);
            }else if(3 == this.statusFilter){ // move express
                this.dataForShow = this.data.filter(d => '3' == d.channel);
            }
        },
        formatTimeTable(date){
            return moment(date).format('DD-MM-YY HH:mm');
        },
        getChannelName(value) {
            return getChannelName(value);
        },
        getStatusThaiName(value){
            return getStatusThaiName(value);
        },
        colorStatus,
        downloadCover: downloadCover,
        downloadMultiCover(){
            this.selected.forEach(s => {
                this.downloadCover(s);
            });
        }
    },
    mounted(){
        this.textSearch = this.$route.query.textSearch || '';
        this.startDate = this.$route.query.startDate || null;
        this.endDate = this.$route.query.endDate || null;
        this.refreshData();
    },
    components: {
        AlertError
    }
};
</script>