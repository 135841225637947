<template>
    <v-container>
        <v-row>
            <v-col class="mt-4">
                <p class="text-h5 white--text">ประวัติการใช้งานระบบ</p>
            </v-col>
        </v-row>
        <v-row>
            <div style="width: 290px;">
                <v-col>
                    <v-menu
                        dark
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            dark
                            v-model="dateText"
                            label="วันที่ค้นหา"
                            hint="เลือกวันที่ค้นหา"
                            persistent-hint
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            color="grey darken-1"
                            v-model="date"
                            no-title
                            @input="menu = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </div>
        </v-row>
        <v-row>
            <v-data-table
                :loading="isLoading"
                loading-text="กำลังโหลด....... กรุณารอซักครู่"
                :headers="headers"
                :items-per-page="100"
                :items="data"
                class="elevation-1"
                style="width: 100%; border: 0.5px solid #aaaaaa;"
                no-data-text="ไม่พบข้อมูล"
                dark
            >
            <template v-slot:[`item.time`]="{ item }">
                <span>{{ formatTime(item.time) }}</span>
            </template>
            <template v-slot:[`item.number`]="{ index }">
                <span>{{ index + 1 }}</span>
            </template>
            </v-data-table>
        </v-row>
        <alert-error :text="errorMessage" ref="alertError" />
    </v-container>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import AlertError from '../../components/AlertError.vue';

export default {
    data: () => ({
        dateText: '',
        menu: false,
        date: new Date().toISOString().substr(0, 10),
        isLoading: false,
        headers: [
            { text: '#', value: 'number', sortable: false },
            { text: 'เวลา', value: 'time', sortable: false  },
            { text: 'ผู้ใช้', value: 'user', sortable: false },
            { text: 'การใช้งาน', value: 'action', sortable: false },
            { text: 'เพิ่มเติม', value: 'description', sortable: false }
        ],
        data: [
            { createdAt: '123', user: 'admin', action: 'login', description: 'hello' }
        ],
        errorMessage: ''
    }),
    methods: {
        refreshDateText(){
            const d1 = moment(this.date);
            this.dateText = d1.format('DD/MM/YYYY');
        },
        async fetchData(){
            this.data = [];
            this.errorMessage = '';
            const date = this.$route.query.date || moment(this.date).format('DD-MM-YYYY');
            this.isLoading = true;
            try{
                const response = await axios.get(process.env.VUE_APP_BASE_URL + '/history/user?date=' + date);
                this.data = response.data;
            }catch(error){
                this.errorMessage = error.response?.data || error.message;
                this.$refs.alertError.show();
            }finally{
                this.isLoading = false;
            }
        },
        formatTime(time){
            return time ? moment(time).format('DD-MM-YYYY HH:mm') : '';
        }
    },
    mounted(){
        const date = this.$route.query.date;
        if(date){
            this.date = new Date(date.split('-')[2], Number(date.split('-')[1] - 1), Number(date.split('-')[0]) + 1).toISOString().substr(0, 10);
        }
        this.refreshDateText();
        this.fetchData();
    },
    watch: {
        date(){
            this.$router.push({ name: 'HistoryHome', query: { date: moment(this.date).format('DD-MM-YYYY') } }).catch(() => {});
            this.refreshDateText();
            this.fetchData();
        }
    },
    components: {
        AlertError
    }
};
</script>