<template>
    <v-container>
        <v-data-table
            dark
            :headers="headers"
            :items="ranges"
            sort-by="calories"
            style="width: 100%; border: 0.5px solid #aaaaaa;"
            class="elevation-1 mt-5 ma-3">
            <template v-slot:top>
            <v-row class="pa-3">
                    <div class="pa-3">
                        <p class="text-h5">ตั้งค่าการส่งสินค้า</p>
                        <p class="subtitle-1 mt-n3">ตั้งค่าการส่งสินค้า</p>
                    </div>
                    <v-spacer></v-spacer>
                    <div class="pa-3">
                        <v-btn 
                        @click="$refs.dialogForm.show()"
                        color="yellow darken-3"
                        small outlined>
                            <v-icon>
                                mdi-plus
                            </v-icon>
                            เพิ่มรายการ
                        </v-btn>
                    </div>
                    
            </v-row>
            </template>
            <template v-slot:[`item.weight`]="{ item }">
                <label class="font-weight-bold">
                    {{item.weight}}
                </label>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    color="yellow darken-3"
                    @click="$refs.dialogForm.show(item.id)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    color="red"
                    @click="$refs.dialogConfirmDelete.show(item.id)"
                >
                    mdi-delete
                </v-icon>
                </template>
                <template v-slot:no-data>
                <v-btn
                    color="primary"
                    @click="initialize"
                >
                    Reset
                </v-btn>
            </template>
        </v-data-table>
        <alert-error :text="errorMessage" ref="alertError"/>
        <alert-success :text="successMessage" ref="alertSuccess" />
        <dialog-form ref="dialogForm" @finish="getData" />
        <dialog-confirm 
            ref="dialogConfirmDelete"
            :title="'ยืนยันลบข้อมูล'" 
            :description="'หากคุณต้องการลบข้อมูลของระยะทางกรุณากดยืนยัน'" 
            @confirm="confirmDelete" />
    </v-container>
</template>

<script>
import { getPricing } from '../../models/setting';
import AlertError from '../../components/AlertError.vue';
import AlertSuccess from '../../components/AlertSuccess.vue';
import DialogForm from '../../components/DialogPricingForm.vue';
import DialogConfirm from '../../components/DialogConfirm.vue';
import axios from 'axios';

export default {
    data: () => ({
        headers: [
            { text: 'น้ำหนักทอง', value: 'weightText' },
            { text: 'ระยะทาง 1 - 5', value: 'range1' },
            { text: 'ระยะทาง 6 - 10', value: 'range2' },
            { text: 'ระยะทาง 11 - 20', value: 'range3' },
            { text: 'ระยะทาง 21 - 30', value: 'range4' },
            { text: 'ระยะทาง 31 - 40', value: 'range5' },
            { text: '', value: 'actions', sortable: false }
        ],
        ranges: [
            { weightText: '0.5 กรัม', range1: '100', range2: '140', range3: '220', range4: '320', range5: '450' },
            { weightText: '1 กรัม', range1: '100', range2: '140', range3: '220', range4: '320', range5: '450' },
            { weightText: '0.125 บาท', range1: '100', range2: '140', range3: '220', range4: '320', range5: '450' },
            { weightText: '1 บาท', range1: '100', range2: '140', range3: '220', range4: '320', range5: '450' },
            { weightText: '5 บาท', range1: '100', range2: '140', range3: '220', range4: '320', range5: '450' }
        ],
        errorMessage: '',
        successMessage: ''
    }),
    methods: {
        async confirmDelete(id){
            let loader = this.$loading.show();
            this.errorMessage = '';
            try{
                await axios.delete(process.env.VUE_APP_BASE_URL + '/settings/' + id);
                this.getData();
            }catch(error){
                console.log(error);
                this.errorMessage = error.response?.data[0]?.msg || error.response?.data?.fail || error.message;
                this.$refs.alertError.show();
            }finally{
                loader.hide();
            }
        },
        async getData(){
            let loader = this.$loading.show();
            this.errorMessage = '';
            try{
                this.ranges = await getPricing();
            }catch(error){
                console.log(error);
                this.errorMessage = error.response?.data[0]?.msg || error.response?.data?.fail || error.message;
                this.$refs.alertError.show();
            }finally{
                loader.hide();
            }
        }
    },
    async mounted(){
        await this.getData();
        this.$vuetify.theme.dark = true;
    },
    components: {
        AlertError,
        AlertSuccess,
        DialogForm,
        DialogConfirm
    }
};
</script>